import React, { useState } from "react";
import {
    Container,
    Col,
    Row,
    Form,
    FormGroup,
    Input,
    Spinner,
    FormFeedback,
} from "reactstrap";
import itag from "../assets/img/itag.png";

import Arrow from "../assets/img/Arrow.svg";
import tick from "../assets/img/tick.svg";
import arrow from "../assets/img/arrowWhite.png";
import { createLead, setErrorResponse } from "../store/actions/Action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
function Formuliar({ step, setStep }) {
    const dispatch = useDispatch();
    const history = useHistory();

    console.log(history);
    const { isLoading, error_response } = useSelector((state) => state.leads);

    const query = history.location.search;
    const site_subid = new URLSearchParams(query).get("oa_id");
    const publisher_id = new URLSearchParams(query).get("site");
    // console.log(publisher_id);
    const [formData, setFormData] = useState({
        language: "nl_NL",
        site_subid: site_subid,
        publisher_id: publisher_id,
        site_custom_url: "http://stopbewustiedereen.gratismeedoen.nl",
        site_custom_name: "stopbewust.iedereen",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        answers: [3836],
    });

    const [success, setSuccess] = useState(false);
    const [bootCheck, setBootCheck] = useState(false);

    const checkNameRegex = (val) => {
        const nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;
        if (val !== "") {
            if (nameRegex.test(val)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };
    const checkEmailRegex = (val) => {
        const emailRegex = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/;
        if (val !== "") {
            if (emailRegex.test(val)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };
    const checkPhoneRegex = (val) => {
        const phoneRegex = /^(0|\+?31|00\s?31)([-\s]?\d[-\s]?){7,8}\d$/;
        if (val !== "") {
            if (phoneRegex.test(val)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };
    const handleChange = (e) => {
        let { name, value } = e.target;
        if (error_response?.[name]) {
            dispatch(setErrorResponse());
        }
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (
            checkNameRegex(formData?.firstname) ||
            checkNameRegex(formData?.lastname) ||
            checkEmailRegex(formData?.email) ||
            checkPhoneRegex(formData?.phone_number)
        ) {
            // alert("Please fill the fields properly to submit");
            return false;
        } else {
            formData.bootCheck = bootCheck;
            const phoneNumberWithoutPunctuation = formData.phone_number
                .replace(/[\s-]/g, "")
                .replace(/^00(\d+)$/, "$1");
            let newArray = {
                ...formData,
                phone_number: phoneNumberWithoutPunctuation,
            };
            console.log("newArray: ", newArray);
            dispatch(
                createLead(
                    newArray,
                    () => {
                        setFormData({
                            language: "",
                            publisher_id: "", // site_id
                            site_subId: "", // oa_id
                            site_custom_url: "",
                            site_custom_name: "",
                            firstname: "",
                            lastname: "",
                            email: "",
                            phone_number: "",
                            answers: [],
                        });
                        setSuccess(true);

                        setTimeout(() => {
                            setStep(16);
                        }, 2000);
                    },
                    () => {
                        setSuccess(false);
                    }
                )
            );
        }
    };

    return (
        <Container fluid className="fomullar-contents">
            <Row>
                <Col
                    md={6}
                    className=" d-flex justify-content-center align-items-center   "
                >
                    <div className=" d-md-block d-none border-right">
                        <Row className="w-100">
                            <Col md={3}></Col>
                            <Col md={8} className="px-0 position-relative">
                                <h2 className="first-heading ">
                                    Dankjewel voor je antwoorden.
                                </h2>
                                <h4 className="my-md-4 my-3 sec-heading">
                                    Uit je antwoorden blijkt dat je wilt stoppen
                                    met roken.
                                </h4>
                                <div className=" fomullar-contents__img">
                                    <img
                                        className=""
                                        src={arrow}
                                        alt=""
                                        width="100px"
                                    />
                                </div>

                                <p className="formPara">
                                    Dat begint bij jouw eerste stap: goede
                                    keuzes en voorbereiding. Het goede nieuws:{" "}
                                    <span className="text-decoration-underline formPara">
                                        je hoeft het niet alleen te doen.
                                    </span>{" "}
                                    Wij helpen je gratis (en vrijblijvend) met
                                    persoonlijk advies over de juiste aanpak.
                                    Ook geven we informatie over methodes en
                                    hulpmiddelen die vergoed worden door de
                                    verzekeraar.{" "}
                                </p>

                                <div className="d-flex mx-2 ">
                                    <div>
                                        <img
                                            src={tick}
                                            className="pb-3 pr-2"
                                            alt=""
                                        />
                                    </div>
                                    <h6 className="mx-2 ms-3 formular-smtext">
                                        Alles over hulpmiddelen en stopmethodes.{" "}
                                    </h6>
                                </div>
                                <div className="d-flex mx-2  ">
                                    <div>
                                        <img
                                            src={tick}
                                            className="pb-3 pr-2"
                                            alt=""
                                        />
                                    </div>
                                    <h6 className="mx-2 ms-3 formular-smtext">
                                        Advies over vergoedingen vanuit de
                                        zorgverzekeraar.{" "}
                                    </h6>
                                </div>
                                <div className="d-flex mx-2 ">
                                    <div>
                                        <img
                                            src={tick}
                                            className="pb-3 pr-2"
                                            alt=""
                                        />
                                    </div>
                                    <h6 className="mx-2 ms-3 formular-smtext">
                                        Geen winstoogmerk: altijd een gratis,
                                        eerlijk advies.{" "}
                                    </h6>
                                </div>
                                <div className="d-flex  mx-2 ">
                                    <div>
                                        <img
                                            src={tick}
                                            className="pb-3 pr-2"
                                            alt=""
                                        />
                                    </div>
                                    <h6 className="mx-2 ms-3 formular-smtext">
                                        Je kan al je rookvragen bespreken.{" "}
                                    </h6>
                                </div>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                    </div>
                </Col>
                <Col
                    md={6}
                    className="  mt-4 pb-md-5 pb-4  d-flex justify-content-center"
                >
                    <>
                        <Row className="w-100">
                            <Col md={10}>
                                <div className="mx-sm-5 mx-0 d-md-none d-block">
                                    <h2 className="first-heading ">
                                        Dankjewel voor je antwoorden.
                                    </h2>
                                    <h4 className="my-2 sec-heading ">
                                        Uit je antwoorden blijkt dat je wilt
                                        stoppen met roken.
                                    </h4>
                                    <h4 className="font-weight-bold fomullar-heading-txt mb-3">
                                        Ben jij klaar voor een gezonder leven?
                                    </h4>
                                </div>
                                <p className="text-center fomullar-heading p-2">
                                    <span className="first-headingBox">
                                        {" "}
                                        Speciale actie:
                                    </span>
                                    <br />{" "}
                                    {/* <span className="first-headingBox"> */}{" "}
                                    Ontvang gratis jouw persoonlijk advies om te
                                    stoppen met roken.
                                    {/* </span> */}
                                </p>
                                <h4 className="font-weight-bold fomullar-heading-txt  d-md-block d-none">
                                    Ben jij klaar voor een gezonder leven?
                                </h4>
                                <div className=" d-md-none d-block">
                                    <div className="d-flex mx-">
                                        <div>
                                            <img
                                                src={tick}
                                                className="pb-2 pr-2"
                                                alt=""
                                            />
                                        </div>
                                        <h6 className="mx-2 ms-3 formular-smtext">
                                            Alles over hulpmiddelen en
                                            stopmethodes.{" "}
                                        </h6>
                                    </div>
                                    <div className="d-flex mx-  ">
                                        <div>
                                            <img
                                                src={tick}
                                                className="pb-2 pr-2"
                                                alt=""
                                            />
                                        </div>
                                        <h6 className="mx-2 ms-3 formular-smtext">
                                            Advies over vergoedingen vanuit de
                                            zorgverzekeraar.{" "}
                                        </h6>
                                    </div>
                                    <div className="d-flex mx- ">
                                        <div>
                                            <img
                                                src={tick}
                                                className="pb-2 pr-2"
                                                alt=""
                                            />
                                        </div>
                                        <h6 className="mx-2 ms-3 formular-smtext">
                                            Geen winstoogmerk: altijd een
                                            gratis, eerlijk advies.{" "}
                                        </h6>
                                    </div>
                                    <div className="d-flex  mx- ">
                                        <div>
                                            <img
                                                src={tick}
                                                className="pb-2 pr-2"
                                                alt=""
                                            />
                                        </div>
                                        <h6 className="mx-2 ms-3 formular-smtext">
                                            Je kan al je rookvragen bespreken.{" "}
                                        </h6>
                                    </div>
                                </div>
                                <Form onSubmit={handleSubmit}>
                                    <Row className="p-2 input-handle">
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input
                                                    placeholder="Voornaam"
                                                    className={`p-3 my-2 text-field`}
                                                    name="firstname"
                                                    required
                                                    value={formData?.firstname}
                                                    onChange={handleChange}
                                                    invalid={
                                                        checkNameRegex(
                                                            formData?.firstname
                                                        ) ||
                                                        error_response?.firstname
                                                    }
                                                />
                                                <FormFeedback>
                                                    Voornaam niet correct
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input
                                                    placeholder="Achternaam"
                                                    className="p-3 my-2 text-field"
                                                    name="lastname"
                                                    value={formData?.lastname}
                                                    required
                                                    onChange={handleChange}
                                                    invalid={
                                                        checkNameRegex(
                                                            formData?.lastname
                                                        ) ||
                                                        error_response?.lastname
                                                    }
                                                />
                                                <FormFeedback>
                                                    Achternaam niet correct
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Input
                                                    placeholder="E-mail adres"
                                                    className="p-3 my-2 text-field"
                                                    name="email"
                                                    required
                                                    value={formData?.email}
                                                    onChange={handleChange}
                                                    invalid={
                                                        checkEmailRegex(
                                                            formData?.email
                                                        ) ||
                                                        error_response?.email
                                                    }
                                                />
                                                <FormFeedback>
                                                    E-mailadres niet correct
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Input
                                                    placeholder="Telefoonnummer"
                                                    className="p-3 my-2 text-field"
                                                    name="phone_number"
                                                    required
                                                    value={
                                                        formData?.phone_number
                                                    }
                                                    invalid={
                                                        checkPhoneRegex(
                                                            formData?.phone_number
                                                        ) ||
                                                        error_response?.phone_number
                                                    }
                                                    onChange={handleChange}
                                                />
                                                <FormFeedback>
                                                    Telefoonnummer niet correct
                                                </FormFeedback>
                                            </FormGroup>

                                            {/* {isLoading ? (
                                                <button
                                                    className="w-100  my-3 btn-fomullar"
                                                    type="submit"
                                                >
                                                    <Spinner size="sm" />
                                                </button>
                                            ) : ( */}
                                            <Input
                                                type="checkbox"
                                                value={bootCheck}
                                                onChange={() =>
                                                    setBootCheck(!bootCheck)
                                                }
                                                style={{ opacity: "0.001" }}
                                            />
                                            <button
                                                className="w-100 d-flex px-5 justify-content-between align-items-center  my-3 btn-fomullar"
                                                // onClick={() => {
                                                //     setStep(step + 1);
                                                // }}
                                                type="submit"
                                                disabled={isLoading}
                                            >
                                                {isLoading ? (
                                                    <span className="d-flex justify-content-center align-items-center mx-auto">
                                                        <Spinner size="sm" />
                                                    </span>
                                                ) : (
                                                    <>
                                                        {" "}
                                                        <span className=" btn-fomullar">
                                                            BEVESTIG
                                                        </span>
                                                        <img
                                                            src={Arrow}
                                                            className="mx-"
                                                            alt=""
                                                        />
                                                    </>
                                                )}
                                                {/* <Col></Col>
                                                    <Col className="btn-fomullar">
                                                        BEVESTIG{" "}
                                                    </Col>
                                                    <Col>
                                                        <img
                                                            src={Arrow}
                                                            className="mx-2"
                                                            alt=""
                                                        />
                                                    </Col> */}
                                            </button>
                                            {/* )} */}

                                            <small className="formsmtext">
                                                Door op “Bevestig” te klikken ga
                                                je er mee akkoord dat er
                                                éénmalig telefonisch contact met
                                                je opgenomen wordt voor een
                                                persoonlijk adviesgesprek.
                                            </small>
                                            <hr></hr>

                                            <div className="d-flex ">
                                                <div>
                                                    <img
                                                        src={itag}
                                                        className="pb-3 mx-sm-2 ms-0 me-2"
                                                        alt=""
                                                    />
                                                </div>

                                                <small className="formsmtext">
                                                    Het doel van de stichting is
                                                    om mensen te helpen met
                                                    juiste informatie en advies
                                                    over stoppen met roken. We
                                                    hebben geen winstoogmerk.{" "}
                                                </small>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </>
                </Col>
            </Row>
        </Container>
    );
}

export default Formuliar;
