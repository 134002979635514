import React from "react";
import { Container, Col, Row } from "reactstrap";
import homeImg from "../assets/img/homeImg.png";
import tick from "../assets/img/tick.svg";
// import iVector from "../assets/img/i-vector.png";
import Arrow from "../assets/img/Arrow.svg";
import sticker from "../assets/img/sitcker.png";
import sticker2 from "../assets/img/Sticker2.png";

function UIOne({ step, setStep }) {
    console.log(step);
    return (
        <Container fluid>
            <Row>
                <Col md={12} lg={6} className=" h-auto  px-0 hero-contents__bg">
                    <Row className="w-100 mx-0 my-">
                        <Col md={3}></Col>
                        <Col
                            md={8}
                            className="py-md-5 py-0 px-lg-0 px-sm-5 px-0  d-flex justify-content-center flex-column position-relative"
                        >
                            <h1 className="fw-bold hero-contents__title">
                                Nationaal Rokers Onderzoek
                            </h1>
                            <p className="hero-contents__text">
                                Doe nu mee en help Stichting Stop Bewust met het
                                onderzoek naar het rookgedrag van Nederlanders.
                            </p>
                            <div className="d-flex align-items-center justify-content-center">
                                <div>
                                    <img src={tick} alt="" />
                                </div>

                                <small className="ms-3 hero-contents__smtext">
                                    Indien je rookt ontvang je gratis
                                    persoonlijk advies over de juiste aanpak om
                                    succesvol te stoppen met roken.
                                </small>
                            </div>
                            <p className="mt-3 hero-contents__smtext2">
                                <b>Het onderzoek duurt slechts 2 minuten.</b>
                            </p>

                            <button
                                className="hero-contents__btn d-flex justify-content-around fw-700 align-items-center pe-2 ps-4 mb-3"
                                onClick={() => {
                                    setStep(1);
                                }}
                            >
                                START DEELNAME
                                <img src={Arrow} alt="" />
                            </button>
                            <Row>
                                {/* 
                                <Col xs="9">
                                 */}
                                <Col xs="12">
                                    <small className="ms-0 mt-2 hero-contents__smtext">
                                        Het doel van de stichting is om mensen
                                        die willen stoppen met roken te
                                        informeren, adviseren en motiveren,
                                        zonder winstoogmerk!
                                    </small>
                                </Col>
                            </Row>
                            <hr />
                            {/* <img
                                src={sticker}
                                className="hero-contents__sticker d-sm-flex d-none"
                            />
                            <img
                                src={sticker2}
                                className="hero-contents__sticker2 "
                            /> */}
                        </Col>
                    </Row>
                </Col>
                <Col md={12} lg={6} className="px-0 hero-contents__img">
                    <img src={homeImg} alt="" />
                </Col>
            </Row>
        </Container>
    );
}
export default UIOne;
