import React, { useEffect, useState } from "react";
import {
    Container,
    Col,
    Row,
    Card,
    Input,
    FormGroup,
    Label,
    Button,
} from "reactstrap";
import { stepData } from "../helpers/stepData";

function UITwo({ step, setStep }) {
    const [selectedCheckbox, setSelectedCheckbox] = useState(null);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [localData, setLocalData] = useState(null);
    const [checkedValues, setCheckedValues] = useState([]);
    console.log(step);
    const checkStep = (id, step) => {
        switch (id) {
            case "q1_yes":
                return 3;
            case "q1_no":
                return 12;
            case "q2_yes":
                return 14;
            case "q2_no":
                return 7;
            case "q3_no":
                return 14;
            case "q3_yes":
                return 18;
            case "q4_yes":
                return 14;
            case "q4_no":
                return 7;
            case "q5_no":
                return 17;
            case "q6_yes":
                return 18;
            case "q6_no":
                return 18;
            case "q7_yes":
                return 7;
            case "q7_no":
                return 7;
            case "q8_no":
                return 16;
            default:
                return step;
        }
    };

    const [showSuccessDanger, setShowSuccessDanger] = useState("");
    const [nextBtn, setNextBtn] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [currentStepsQuestionIds, setCurrentStepsQuestionIds] = useState([]);
    function findCommonElements(arr1, arr2) {
        return arr1?.filter((value) => arr2?.includes(value));
    }
    // const currentStepsQuestionIds = localData
    //     ?.find((item) => item.step === step)
    //     ?.filter((step) =>
    //         step.questions?.map((quest) => {
    //             return quest.id;
    //         })
    //     );
    console.log(
        selectedCheckboxes,
        step,
        // currentStepsQuestionIds,
        "checkboxes selected"
    );

    const handleCheckboxChange = (e, checkboxId) => {
        const checked = e.target.checked;
        if (checked) {
            setSelectedCheckboxes([...selectedCheckboxes, checkboxId]);
            setNextBtn(true);
        } else {
            setSelectedCheckboxes(
                selectedCheckboxes.filter((id) => id !== checkboxId)
            );
            setNextBtn(true);
        }
        // if (step == 2) {

        // localData?.find((item) => item.step === step).questions.map
        // selectedCheckboxes.find((id)=> id==)
        // }
    };
    console.log(
        "currentStepsQuestionIds",
        step,
        localData,
        currentStepsQuestionIds,
        findCommonElements(selectedCheckboxes, currentStepsQuestionIds),
        showButton
    );

    const handleRadioChange = (e, id, item, ques10) => {
        const isChecked = e;
        if (isChecked && selectedCheckbox !== id) {
            setSelectedCheckbox(id);
            setSelectedCheckboxes([id]);
        } else if (!isChecked && selectedCheckbox === id) {
            setSelectedCheckbox(null);
            setSelectedCheckboxes([]);
        }

        if (ques10) {
            setShowSuccessDanger(id);
            setTimeout(() => {
                window.scrollTo(0, 0);
                setShowSuccessDanger(id);
                setStep(checkStep(id, item.step) + 1);
            }, 2000);
        } else {
            setTimeout(() => {
                if (step == 3 || step == 5) {
                    return;
                } else {
                    setStep(checkStep(id, item.step) + 1);
                    window.scrollTo(0, 0);
                }
            }, 300);
        }
    };
    useEffect(() => {
        // setTimeout(() => {
        let commonElements = findCommonElements(
            selectedCheckboxes,
            currentStepsQuestionIds
        );

        if (commonElements.length > 0) {
            setShowButton(true);
        } else {
            if (showButton) {
                setShowButton(false);
            }
        }
        // }, 500);
        console.log(commonElements);
    }, [selectedCheckboxes, currentStepsQuestionIds]);
    useEffect(() => {
        const currentStepObject = localData?.find(
            (item) => item?.step === step
        );
        const tempcurrentStepsQuestionIds = currentStepObject?.questions?.map(
            (ques) => {
                return ques?.id;
            }
        );
        setCurrentStepsQuestionIds(tempcurrentStepsQuestionIds);
    }, [step]);
    useEffect(() => {
        setLocalData(stepData);
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {localData
                ?.filter((item) => item.step === step)
                ?.map((item) => {
                    return (
                        <>
                            <Container fluid>
                                <Row noGutters>
                                    <Col
                                        md={6}
                                        className=" d-flex justify-content-center  hero-contents__bg  px-0  shadow-lg  "
                                    >
                                        <Row className="w-100 m-auto">
                                            <Col lg={3}></Col>
                                            <Col
                                                lg={7}
                                                className="py-md-5 py-0 px-lg-0 px-sm-5 px-0"
                                            >
                                                <h3 className="label__head mb-4">
                                                    {item?.title}
                                                </h3>
                                                {item?.questions.length > 0
                                                    ? item?.questions?.map(
                                                          (ques) => {
                                                              return (
                                                                  <Card
                                                                      key={
                                                                          ques?.id
                                                                      }
                                                                      className={`mt-4 p--2 hero-contents__card pointer-cursor border-0 ${
                                                                          showSuccessDanger !==
                                                                              "" &&
                                                                          (showSuccessDanger ===
                                                                              ques?.id ||
                                                                              ques?.bgClass ===
                                                                                  "setbackground-color__green") &&
                                                                          ques?.bgClass
                                                                      } 
                                                                  ${
                                                                      selectedCheckboxes.includes(
                                                                          ques?.id
                                                                      )
                                                                          ? "cardColor"
                                                                          : ""
                                                                  }`}
                                                                      onClick={(
                                                                          e
                                                                      ) => {
                                                                          handleCheckboxChange(
                                                                              e,
                                                                              ques?.id
                                                                          );
                                                                      }}
                                                                  >
                                                                      <div
                                                                          className="p-2 d-flex "
                                                                          onClick={(
                                                                              e
                                                                          ) => {
                                                                              if (
                                                                                  step ===
                                                                                      5 ||
                                                                                  step ===
                                                                                      3 ||
                                                                                  step ===
                                                                                      14
                                                                              ) {
                                                                                  handleCheckboxChange(
                                                                                      e,
                                                                                      ques?.id
                                                                                  );
                                                                              } else {
                                                                                  handleRadioChange(
                                                                                      true,
                                                                                      ques.id,
                                                                                      item,
                                                                                      ques?.bgClass,
                                                                                      step
                                                                                  );
                                                                              }
                                                                          }}
                                                                      >
                                                                          <FormGroup className="handleFormgroup w-100">
                                                                              {step ===
                                                                                  5 ||
                                                                              step ===
                                                                                  3 ||
                                                                              step ===
                                                                                  14 ? (
                                                                                  <>
                                                                                      <Input
                                                                                          className="mt-0"
                                                                                          type="checkbox"
                                                                                          id={`checkbox-${ques.id}`}
                                                                                          onChange={(
                                                                                              e
                                                                                          ) =>
                                                                                              handleCheckboxChange(
                                                                                                  e,
                                                                                                  ques?.id
                                                                                              )
                                                                                          }
                                                                                          checked={selectedCheckboxes.includes(
                                                                                              ques?.id
                                                                                          )}
                                                                                      />
                                                                                      <Label
                                                                                          className="text-dark w-100 ms-3 pointer-cursor label__text"
                                                                                          htmlFor={`checkbox-${ques.id}`}
                                                                                      >
                                                                                          {
                                                                                              ques?.text
                                                                                          }
                                                                                      </Label>
                                                                                  </>
                                                                              ) : (
                                                                                  <>
                                                                                      <Input
                                                                                          type={`${"radio"}`}
                                                                                          className="checkboxhandle mt-0"
                                                                                          name="check"
                                                                                          id={`checkbox-${ques.id}`}
                                                                                          checked={
                                                                                              selectedCheckbox ===
                                                                                              ques.id
                                                                                          }
                                                                                      />
                                                                                      <Label
                                                                                          className="text-dark ms-3 w-100 pointer-cursor label__text"
                                                                                          htmlFor={`checkbox-${ques.id}`}
                                                                                      >
                                                                                          {
                                                                                              ques?.text
                                                                                          }
                                                                                      </Label>
                                                                                  </>
                                                                              )}
                                                                          </FormGroup>
                                                                      </div>
                                                                  </Card>
                                                              );
                                                          }
                                                      )
                                                    : step === 8 && (
                                                          <div className="text-center mt-4">
                                                              {/* <h3>
                                                                  Bedankt voor
                                                                  jouw deelname!
                                                              </h3>
                                                              <h3>
                                                                  Wij zijn blij
                                                                  om te zien dat
                                                                  jij niet
                                                                  rookt.
                                                              </h3> */}
                                                              <Button
                                                                  className="optBtn"
                                                                  onClick={() =>
                                                                      setStep(
                                                                          step +
                                                                              1
                                                                      )
                                                                  }
                                                              >
                                                                  Ga verder
                                                              </Button>
                                                          </div>
                                                      )}
                                                {step === 20 && (
                                                    <div className="text-center mt-4">
                                                        <h3>
                                                            Bedankt voor jouw
                                                            deelname!
                                                        </h3>
                                                        <h3>
                                                            Wij zijn blij om te
                                                            zien dat jij niet
                                                            rookt.
                                                        </h3>
                                                        {/* <Button
                                                                  className="optBtn"
                                                                  onClick={() =>
                                                                      setStep(
                                                                          step +
                                                                              1
                                                                      )
                                                                  }
                                                              >
                                                                  Ga verder
                                                              </Button> */}
                                                    </div>
                                                )}
                                                {nextBtn ? (
                                                    <>
                                                        {
                                                            // step === 2 ||
                                                            step === 14 &&
                                                            showButton ? (
                                                                <div className="text-center mt-4">
                                                                    <Button
                                                                        className="optBtn"
                                                                        onClick={() => {
                                                                            setStep(
                                                                                step +
                                                                                    1
                                                                            );
                                                                        }}
                                                                    >
                                                                        Volgende
                                                                    </Button>
                                                                </div>
                                                            ) : null
                                                        }
                                                    </>
                                                ) : (
                                                    ""
                                                )}

                                                {step === 5 && showButton ? (
                                                    <div className="text-center mt-4">
                                                        <Button
                                                            className="optBtn"
                                                            onClick={() => {
                                                                setStep(
                                                                    step + 1
                                                                );
                                                            }}
                                                        >
                                                            Volgende
                                                        </Button>
                                                    </div>
                                                ) : null}
                                                {step === 3 && showButton ? (
                                                    <div className="text-center mt-4">
                                                        <Button
                                                            className="optBtn"
                                                            onClick={() => {
                                                                setStep(
                                                                    step + 1
                                                                );
                                                            }}
                                                        >
                                                            Volgende
                                                        </Button>
                                                    </div>
                                                ) : null}
                                                {step === 18 && showButton ? (
                                                    <div className="text-center mt-4">
                                                        {/* <Button
                                                            className="optBtn"
                                                            onClick={() => {
                                                                setStep(
                                                                    step + 1
                                                                );
                                                            }}
                                                        >
                                                            Volgende
                                                        </Button> */}
                                                    </div>
                                                ) : null}
                                                {step === 19 && showButton ? (
                                                    <div className="text-center mt-4">
                                                        {/* <Button
                                                            className="optBtn"
                                                            onClick={() => {
                                                                setStep(
                                                                    step + 1
                                                                );
                                                            }}
                                                        >
                                                            Volgende
                                                        </Button> */}
                                                    </div>
                                                ) : null}
                                            </Col>
                                            <Col lg={2}></Col>
                                        </Row>
                                    </Col>
                                    <Col
                                        md={6}
                                        className="px-0 hero-contents__img"
                                    >
                                        <img
                                            src={item?.imgSrc}
                                            className="w-100 h-100 pl--3"
                                            alt=""
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    );
                })}
        </>
    );
}
export default UITwo;
