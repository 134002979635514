import React from "react";
import stars from "../assets/img/stars.png";
import stars1 from "../assets/img/stars1.png";
import { Card, CardBody, Row, Col, Container } from "reactstrap";
import heart from "../assets/img/heart.png";
function TestimonialCard() {
    return (
        <Container className="my-5 testimonials">
            <Col lg={10} className="m-auto">
                <Row>
                    <Col md={4}>
                        <Card className="testimonials__cards my-2">
                            <CardBody>
                                <img src={stars} className="mt-3" alt="" />

                                <p className="my-4">
                                    “Goede informatie en ondersteuning bij
                                    Stichting Stop Bewust voor mensen die echt
                                    willen stoppen.”
                                </p>
                                <h5 className="my-2">
                                    <b>ANDY RIJSKAMP</b>
                                </h5>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="testimonials__cards position-relative my-2">
                            <img
                                className="position-absolute testimonials__cards-heart"
                                src={heart}
                                height="auto"
                                width="30px"
                                alt=""
                            />
                            <CardBody>
                                <img src={stars1} className="mt-3" alt="" />

                                <p className="my-4">
                                    “Goed contact en goede voorlichting. Je kan
                                    altijd bellen en verder kan je het ook echt
                                    zelf doen!”
                                </p>
                                <h5 className="my-2">
                                    <b>MARIANNE TRIJSBURG</b>
                                </h5>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="testimonials__cards my-2">
                            <CardBody>
                                <img src={stars1} className="my-2" alt="" />

                                <p className="my-4">
                                    “Ik raad Stichting Stop Bewust zeer zeker
                                    aan, goed advies en informatie.”
                                </p>
                                <h5 className="mt-5">
                                    <b>ESTHER HENDRIKS</b>
                                </h5>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Container>
    );
}

export default TestimonialCard;
