import React from "react";
import { Container, Col, Row } from "reactstrap";

function ThankNo() {
    return (
        <Container fluid>
            <Row>
                <Col
                    md={12}
                    className="d-flex justify-content-center align-items-center thanks-contents  "
                >
                    {/* <Col md={3}></Col> */}
                    <Col md={7} className="my-5 text-center  py-5">
                        <h1 className="justify-content-center  thanks-contents__thankyes align-items-center m-5 ">
                            Bedankt voor uw deelname namens Stichting Stop
                            Bewust!
                        </h1>
                    </Col>
                    {/* <Col md={2}></Col> */}
                </Col>
            </Row>
        </Container>
    );
}

export default ThankNo;
