const Main = props => {
	return (
		<>
			
			{props.children}
			
		</>
	);
};

export default Main;
