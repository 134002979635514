const Auth = props => {
	return (
		<>
			<header>Auth Header</header>
			{props.children}
			<footer>Auth Footer</footer>
		</>
	);
};

export default Auth;
