import { RepositoryFactory } from "../../repository/RepositoryFactory";
var lead = RepositoryFactory.get("leads");

export const createLead =
    (data, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
        let { bootCheck, ...payload } = data;
        console.log(payload);
        if (bootCheck) {
            dispatch({
                type: "LEAD_ERROR",
                payload: "data",
            });
            onSuccess();
        } else {
            try {
                // alert("jkg");
                dispatch({ type: "LEAD_LOADER", payload: true });

                const { data } = await lead.postLead(payload);
                dispatch({ type: "PROFILE", payload: data });
                onSuccess();
            } catch (error) {
                console.log(error.response.data.error);
                if (error.response.data.error == "Conflict - duplicate") {
                    dispatch({
                        type: "LEAD_ERROR",
                        payload: error.response.data.error,
                    });
                    onSuccess();
                } else {
                    dispatch({ type: "LEAD_LOADER", payload: false });
                    let e = error.response.data.error
                        .match(/'([^']+)'/)[0]
                        .slice(1, -1);
                    dispatch({
                        type: "ERROR_RESPONSE",
                        payload: { [e]: true },
                    });
                }
                // console.log(error.response.data.error);
            }
        }
    };
export const setErrorResponse = () => async (dispatch) => {
    dispatch({
        type: "ERROR_RESPONSE",
        payload: null,
    });
};
