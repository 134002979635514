// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import leadProfile from "./leadReducer";

export let rootReducer = combineReducers({
    authUser: authUserReducer,
    leads: leadProfile,
});

export default rootReducer;
