import { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import TestimonialCard from "../components/TestimonialCard";
import HeroSection from "../components/HeroSection";
import UITwo from "../components/UITwo";
import Formuliar from "../components/Formuliar";
import ThankYes from "../components/ThankYes";
import ThankNo from "../components/ThankNo";
import Footer from "../components/Footer";

const Home = (props) => {
    const [step, setStep] = useState(0);
    const [answers, setAnswers] = useState([]);
    return (
        <>
            <Header />
            {step == 0 && <HeroSection step={step} setStep={setStep} />}
            <UITwo
                step={step}
                setStep={setStep}
                answers={answers}
                setAnswers={setAnswers}
            />
            {step == 15 && <Formuliar step={step} setStep={setStep} />}
            {step == 16 && <ThankYes />}
            {step == 17 && <ThankNo />}
            {/* <Formuliar step={step} setStep={setStep} />
            <ThankYes /> */}
            <TestimonialCard />
            <Footer />
        </>
    );
};

export default Home;
