import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Col, Row } from "reactstrap";

// import img from "../assets/img/uiEightImage.png";

function ThankYes() {
    const [idToStore, setIdToStore] = useState(null);

    const { profile } = useSelector((state) => state.leads);

    useEffect(() => {
        setIdToStore(profile?.profile?.id);
        // let tmps =
        //     idToStore != undefined && idToStore != "" && idToStore != null
        //         ? "yes"
        //         : "no";
        // alert(tmps);
    }, []);
    return (
        <Container fluid>
            <Row>
                <Col
                    md={12}
                    className="d-flex justify-content-center align-items-center  thanks-contents py-5"
                >
                    <Col md={7} className="my-5  text-center">
                        <h1 className="justify-content-center thanks-contents__thankNo align-items-center ">
                            Bedankt voor uw deelname namens Stichting Stop
                            Bewust!
                        </h1>
                        <span className="thanks-contents__thankNo-text">
                            Wij nemen zo spoedig mogelijk contact met je op over
                            jouw persoonlijke advies.
                        </span>
                    </Col>
                    {idToStore != undefined &&
                    idToStore != "" &&
                    idToStore != null ? (
                        <img
                            referrerpolicy="no-referrer-when-downgrade"
                            src={`https://republish.gratismeedoen.nl/m/4641/c53454de0e50/?event=5640&unique_conversion_id=${idToStore}`}
                            style={{
                                width: "1px",
                                height: "1px",
                                border: "0px",
                            }}
                        />
                    ) : (
                        ""
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default ThankYes;
